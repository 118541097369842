<template>
    <div class="registered_page">
        <BannerImg :bannerImgUrl="formData.bannerImgUrl"></BannerImg>
        <div class="module1">
            <CourseTitle :title="formData.certificateValueName" contText="">
            </CourseTitle>
            <div class="content">
                <div class="qualification_certificate flex">
                    <div class="qualification_certificate_list"
                        v-for="(item, index) in formData.registrationCertificateValueVOS" :key="index">
                        <div class="mask_layers"></div>
                        <div class="cont">
                            <div class="title flex-center">
                                <span class="num display">0{{ index + 1 }}</span>
                                {{ item.certificateValueName }}
                            </div>
                            <div class="qualification_certificate_text">
                                {{ item.valueIntroduce }}
                            </div>
                            <div class="learnMore pointer" @click="service">
                                {{ item.buttonName }} >>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module2">
            <div class="content">
                <CourseTitle :title="formData.thoseWhoFitName" contText="">
                </CourseTitle>
                <div class="getCertificate flex">
                    <div class="getCertificate_list flex-center column pointer"
                        v-for="(item, index) in formData.registrationThoseWhoFitVOS" :key="index">
                        <img :src="item.thoseWhoFitImgUrl" alt="" />
                        <div class="certificate_name">{{ item.thoseWhoFitName }}</div>
                        <div class="certificate_type flex flex-wrap">
                            <div class="certificate_type_list display" v-for="(items, indexs) in item.thoseWhoFitLabel"
                                :key="indexs">
                                {{ items }}
                            </div>
                        </div>
                        <div class="certificate_type_details astrict">
                            {{ item.thoseWhoFitIntroduce }}
                        </div>
                        <div class="btn display display" @click="service">了解详情</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module3">
            <CoreCourses :coreCourseTitle="formData.coreCourseTitle"
                :registrationCoreCourse="formData.registrationCoreCourseVOS"
                :coreCourses="formData.registrationCoreCourseVOS[0]"></CoreCourses>
        </div>
        <div class="certificate_box">
            <CourseTitle :title="'证书样式'" contText=""> </CourseTitle>
            <div class="content display">
                <img class="certificate_img" :src="formData.certificateUrl" alt="" />
            </div>
        </div>
        <div class="module4">
            <div class="content">
                <CourseTitle :title="formData.applicationProcessName" contText="">
                </CourseTitle>
                <div class="process display">
                    <div class="process_list display column"
                        v-for="(item, index) in formData.registrationApplicationProcessVOS" :key="index">
                        <img class="icon_jt" src="@/assets/img/certificate/jt.png" alt="" />
                        <img class="process_img" :src="item.applicationProcessImgUrl" alt="" />
                        <div class="process_name">{{ item.applicationProcessName }}</div>
                        <div class="process_name processIntroduce">
                            {{ item.processIntroduce }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module5">
            <div class="content">
                <CourseTitle :title="formData.gejAdvantageName" contText="">
                </CourseTitle>
                <div class="advantage flex flex-wrap">
                    <div class="advantage_cont flex">
                        <div class="cover_image">
                            <img class="advantage_img" :src="gejAdvantage.gejAdvantageImgUrl" alt="" />
                            <div class="mask"></div>
                            <div class="cont flex">
                                <div class="advantage_name">
                                    {{ gejAdvantage.gejAdvantageName }}
                                </div>
                                <div class="line"></div>
                                <div class="advantage_describe astrict">
                                    {{ gejAdvantage.gejAdvantageIntroduce }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="advantage_div flex flex-wrap">
                                <div class="advantage_list flex-center column pointer"
                                    v-for="(item, index) in formData.registrationGejAdvantageVOS" :key="index"
                                    @click="selectBtn(item, index)">
                                    <div class="advantage_name">{{ item.gejAdvantageName }}</div>
                                    <div class="advantage_characteristics">
                                        {{ item.gejAdvantageIntroduce.slice(0, 6) }}
                                    </div>
                                </div>

                                <div class="btn display pointer" @click="service">
                                    预约到校参观体验 >>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module6">
            <CourseTitle :title="formData.teachingTeamName" :contText="formData.teachingTeamIntroduce">
            </CourseTitle>
            <Lecturer :lecturerList="formData.teacherVOS"></Lecturer>
        </div>
        <div class="module7">
            <LearningMaterials :type="1" :options="formData.registrationTeachingMaterialsVOS"
                :courseTitle="formData.teachingMaterialsName"></LearningMaterials>
        </div>
        <div class="module8">
            <div class="content">
                <CourseTitle :title="formData.preparationMaterialsName" contText="">
                </CourseTitle>
                <CollectData :collectList="formData.registrationPreparationMaterialsVOS"></CollectData>
            </div>
        </div>
        <div class="module9">
            <ExaminationInformation :title="formData.applicationConsultationName"
                :contText="formData.applicationConsultationIntroduce"
                :province="formData.registrationApplicationConsultationProvinceVOS"
                :question="formData.registrationApplicationConsultationQuestionVOS"></ExaminationInformation>
        </div>
        <div class="module10">
            <SignUp :courseType="1" :courseId="this.formData.id"></SignUp>
        </div>
    </div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import CourseTitle from './components/courseTitle.vue'
import Lecturer from '@/components/lecturer'
import CoreCourses from '@/components/coreCourses'
import SignUp from '@/components/signUp'
import LearningMaterials from '@/components/learningMaterials'
import CollectData from '@/components/collectData'
import ExaminationInformation from '@/components/examinationInformation'
export default {
    components: {
        BannerImg,
        CourseTitle,
        Lecturer,
        SignUp,
        CoreCourses,
        LearningMaterials,
        CollectData,
        ExaminationInformation,
    },
    data() {
        return {
            value: '',
            classificationIndex: 0,
            certificate: [{}, {}, {}, {}],
            getCertificate: [{}, {}, {}],
            formData: {},
            gejAdvantage: {},
        }
    },
    mounted() {
        document.documentElement.scrollTop = 0
        this.init()
    },
    methods: {
        //获取初始化数据
        init() {
            this.api.findRegistrationInfo().then((res) => {
                if (res.code == 0) {
                    res.data.registrationThoseWhoFitVOS.forEach((item) => {
                        item.thoseWhoFitLabel = item.thoseWhoFitLabel.split(',')
                    })
                    res.data.registrationTeachingMaterialsVOS.forEach((item) => {
                        item.value = item.id
                        item.label = item.teachingMaterialsName
                    })
                    this.formData = res.data
                    this.gejAdvantage = this.formData.registrationGejAdvantageVOS[0]
                }
            })
        },

        // 点击果尔佳优势
        selectBtn(item, index) {
            this.gejAdvantage = item
        },
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.registered_page {
    .module1 {
        height: 515px;
        background: url('../../assets/img/course/bg5.png') no-repeat;
        background-size: 100% 100%;
        padding-bottom: 77px;

        .qualification_certificate {
            margin-top: 31px;

            .qualification_certificate_list:nth-child(1) {
                margin-left: 0px !important;
            }

            .qualification_certificate_list:hover {
                box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);
                border-radius: 8px;

                .mask_layers {
                    width: 280px;
                    height: 290px;
                    background: #fbfbfa;
                    opacity: 0.5;
                    position: absolute;
                    top: 0;
                    left: 0;
                    border-radius: 8px;
                }
            }

            .qualification_certificate_list {
                width: 280px;
                height: 290px;
                margin-left: 23px;
                padding-top: 41px;
                padding-left: 28px;
                box-sizing: border-box;
                position: relative;
                border-radius: 8px;

                .cont {
                    width: 280px;
                    height: 290px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    padding-top: 41px;
                    padding-left: 28px;
                    box-sizing: border-box;
                    border-radius: 8px;

                    .title {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #003c7e;
                        line-height: 24px;

                        span {
                            width: 45px;
                            height: 45px;
                            background: #003c7e;
                            border-radius: 50%;
                            font-size: 24px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #fafafa;
                            line-height: 24px;
                            margin-right: 10px;
                        }
                    }

                    .qualification_certificate_text {
                        width: 238px;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 24px;
                        margin-top: 30px;
                    }

                    .learnMore {
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #003c7e;
                        line-height: 18px;
                        position: absolute;
                        top: 221px;
                    }
                }
            }
        }
    }

    .module2 {
        padding-bottom: 93px;

        .getCertificate {
            margin-top: 31px;

            .getCertificate_list:nth-child(1) {
                margin-left: 0px !important;
            }

            .getCertificate_list {
                width: 380px;
                height: 607px;
                background: #ffffff;
                box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);
                border-radius: 8px;
                padding-top: 36px;
                box-sizing: border-box;
                margin-left: 29px;

                img {
                    width: 200px;
                    height: 200px;
                }

                .certificate_name {
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #003c7e;
                    margin-top: 29px;
                    line-height: 24px;
                }

                .certificate_type::-webkit-scrollbar {
                    display: none;
                }

                .certificate_type {
                    height: 120px;
                    margin-top: 29px;
                    padding: 0px 44px;
                    box-sizing: border-box;
                    overflow: hidden;
                    overflow-y: scroll;

                    .certificate_type_list:nth-child(2n + 1) {
                        margin-left: 0 !important;
                    }

                    .certificate_type_list {
                        width: 140px;
                        height: 44px;
                        background: #ffffff;
                        border: 1px solid #8aa5c3;
                        border-radius: 22px;
                        font-size: 18px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #003c7e;
                        margin-left: 12px;
                        margin-bottom: 16px;
                    }
                }

                .certificate_type_details {
                    width: 329px;
                    height: 38px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    margin-top: 22px;
                }

                .btn {
                    width: 180px;
                    height: 44px;
                    background: #ff881e;
                    border-radius: 5px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #ffffff;
                    margin: 0 auto;
                    margin-top: 26px;
                }
            }
        }
    }

    .module4 {
        height: 468px;

        .process {
            margin-top: 30px;

            .process_list:nth-child(1) {
                margin-left: 0px !important;
            }

            .process_list:last-child {
                .icon_jt {
                    display: none;
                }
            }

            .process_list {
                margin: 0px 43px;
                position: relative;

                .icon_jt {
                    width: 38px;
                    height: 20px;
                    position: absolute;
                    top: 55px;
                    right: -62px;
                }

                .process_img {
                    width: 120px;
                    width: 120px;
                }

                .process_name {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 14px;
                    margin-top: 21px;
                }

                .processIntroduce {
                    height: 78px;
                    line-height: 24px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }

    .module5 {
        height: 760px;
        background: url('../../assets/img/course/bg6.png') no-repeat;
        background-size: 100% 100%;
        padding-bottom: 83px;

        .advantage {
            width: 1200px;
            height: 480px;
            margin-top: 42px;
            background: url('../../assets/img/course/bg18.png') no-repeat center;
            background-size: 100% 100%;
            padding: 23px;
            padding-top: 30px;
            box-sizing: border-box;

            .advantage_cont {
                .cover_image {
                    width: 770px;
                    height: 414px;
                    position: relative;

                    .advantage_img {
                        width: 770px;
                        height: 414px;
                    }

                    .mask {
                        width: 770px;
                        height: 76px;
                        background: #333;
                        opacity: 0.8;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                    }

                    .cont {
                        width: 770px;
                        height: 76px;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        z-index: 2;

                        .advantage_name {
                            height: 76px;
                            font-size: 30px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #ffffff;
                            line-height: 76px;
                            margin-left: 38px;
                        }

                        .line {
                            width: 1px;
                            height: 64px;
                            background: #dfd8d9;
                            margin-top: 6px;
                            margin-left: 26px;
                        }

                        .advantage_describe {
                            width: 533px;
                            height: 46px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #fffefe;
                            line-height: 24px;
                            margin-left: 37px;
                            margin-top: 16px;
                        }
                    }
                }

                .advantage_div {
                    width: 385px;
                    padding-top: 10px;
                    padding-left: 17px;

                    .advantage_list:nth-child(2n + 1) {
                        margin-left: 0 !important;
                    }

                    .advantage_list {
                        width: 176px;
                        height: 140px;
                        background: #eff2fa;
                        border-radius: 8px;
                        margin-left: 16px;
                        margin-bottom: 14px;

                        .advantage_name {
                            font-size: 24px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #003c7e;
                            line-height: 24px;
                            margin-top: 41px;
                        }

                        .advantage_characteristics {
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                            line-height: 16px;
                            margin-top: 22px;
                        }
                    }
                }

                .btn {
                    width: 365px;
                    height: 50px;
                    background: #ff881e;
                    border-radius: 5px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #ffffff;
                    margin-top: 15px;
                }
            }
        }
    }

    .module6 {
        padding-bottom: 110px;
    }

    .module8 {
        padding-bottom: 90px;
    }

    .certificate_box {
        height: 685px;
        background: url('../../assets/img/course/bg19.png') no-repeat;
        background-size: 100% 100%;

        .content {
            .certificate_img {
                width: 310px;
                height: 440px;
                margin-top: 21px;
            }
        }
    }
}</style>